



































































import qualityScoreView from "@/popupcomponents/qualityScoreView/qualityScoreView";
export default qualityScoreView;
