import { render, staticRenderFns } from "./qualityScoreView.vue?vue&type=template&id=363f259e&scoped=true&"
import script from "./qualityScoreView.vue?vue&type=script&lang=ts&"
export * from "./qualityScoreView.vue?vue&type=script&lang=ts&"
import style0 from "./qualityScoreView.less?vue&type=style&index=0&id=363f259e&lang=less&scoped=true&"
import style1 from "../../components/datadashboardcomponent/datadashboardsidepanel/fileUpload.less?vue&type=style&index=1&id=363f259e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "363f259e",
  null
  
)

export default component.exports