import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from "@/utilities/commonFunctions";
import { deleteQualityScore } from "@/services/dashboard/dashboardService";
import APP_CONST from '@/constants/AppConst';
 
@Component

export default class qualityScoreView extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    private hover:boolean = false;
    private hoverVal:string = "";
    private hoverStyleObj:any = {};
    public deleteScore: boolean = false;
    public siteId:number=0;
    public mobileView:boolean = false; 
    public scoreId : number = 0;
    public bottomViewTooltip: boolean = false;

    @Prop()
    qualityScoreObj!:any

    @Prop()
    index!:1

    @Prop()
    fileArray!:any
    @Prop()
    qualityScoreId!:number

    @Prop()
    siteLength!:number

    @Prop()
    roleId!:number;

    @Prop()
    selectedSiteId!:number;
    
    toolTiphoverText: string = '';
    toolTiphovering: boolean = false;
    toolTipHoverIndex: number = -1;

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    formatDateView(date:string){
        return APP_UTILITIES.formatDate(date);
    }

    editScore(){
      this.$emit('editMode', this.qualityScoreObj, this.qualityScoreId);
    }
  downLoadFile(ulr:string,localUrl:string){
    if(!APP_UTILITIES.isMobile()){
        window.open(ulr,'_self')
         }
  }
  mounted(){
    let {siteId} = APP_UTILITIES.coreids();
    this.siteId=siteId;
    this.mobileView = document.documentElement.clientWidth <= 768 ? true : false;
    window.addEventListener("resize", APP_UTILITIES.debounce(this.isMobile));
  }
  toolTipcallHover(index:number, text: string) {
    this. toolTiphoverText= text;
    this.toolTiphovering= true;
    this.toolTipHoverIndex = index;
}

destroyed() {
  window.removeEventListener("resize", this.isMobile);
}

isMobile(){
  this.mobileView =APP_UTILITIES.isMobile()
}

toolTipcallHoverOut() {
    this. toolTiphoverText= '';
    this. toolTiphovering= false;
    this.toolTipHoverIndex = -1;
}  

callHover(hoverText:string,$event:any){
    if($event.target.offsetWidth < $event.target.scrollWidth){
      this.hover = true;
      this.hoverVal = hoverText;
      const boundBox = $event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.hoverStyleObj = {
          top:(coordY + 30).toString() + "px",
          left:(coordX + 20).toString() + "px"
      }
    }
  }

  callHoverOut(){
    this.hover = false;
    this.hoverVal = "";
    this.hoverStyleObj = {};
  }

  showDeletePopUp(qualityScoreObj: any, i: any){
    this.deleteScore = !this.deleteScore;
    this.scoreId = qualityScoreObj.id;
    const windowHeight = screen.height - 200;
    const bottomTooltip = document.getElementsByClassName('delete-view')[0];
    const deleteIconCurrent:any = document.getElementById(`deleteCurrentScore${i}`);
    if( deleteIconCurrent.y >  windowHeight){
          this.bottomViewTooltip = true;
        }
    else{
          this.bottomViewTooltip = false;
          bottomTooltip.classList.remove("bottom-view-tooltip");
        }
  }

  deleteQualityScores(){
    const id = this.scoreId? this.scoreId : this.qualityScoreId;
    deleteQualityScore(id).then((res : any) => {
      if (res.status === APP_CONST.RESPONSE_200) {
        this.deleteScore = false;
        this.$emit('deleteInfoCheck', id, this.qualityScoreObj);
      } 
    }).catch((error : any) => {
    })  
  }

  closeDeletePopUp(){
    this.deleteScore = false;
  }
}